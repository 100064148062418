<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import { VclList } from "vue-content-loading";
import Stat from "@/components/widgets/stat";

export default {
  locales: {
    pt: {
      Team: "Equipe",
      "Pending Confirmations": "Confirmações Pendentes",
      Username: "Usuário",
      Name: "Nome",
      Date: "Data",
      "E-mail": "E-mail",
      Cellphone: "Celular",
      Boards: "Tabuleiros",
      Status: "Status",
      Pending: "Pendente",
      Approved: "Aprovado",
      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.",
      "No records found.": "Nenhum registro encontrado.",
      Graduation: "Graduação",
    },
    es: {
      Team: "Equipe",
      "Pending Confirmations": "Confirmaciones Pendientes",
      Username: "Nombre de Usuario",
      Name: "Nombre",
      Date: "Data",
      "E-mail": "E-mail",
      Cellphone: "Celular",
      Boards: "Tableros",
      Status: "Status",
      Pending: "Pendiente",
      Approved: "Aprobado",
      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.",
      "No records found.": "No se encontraron registros.",
      Graduation: "Graduación",
    },
  },
  components: {
    Layout,
    VclList,
    Stat,
  },
  data() {
    return {
      page: 1,
      pages: 0,

      statData: null,

      table: {
        body: null,
        loading: true,
        errored: false,
        empty: false,
      },
    };
  },
  methods: {
    getTeam() {
      api
        .get("network/team?page=" + this.page)
        .then((response) => {
          if (response.data.status == "success") {
            this.table.body = response.data.list;
            this.pages = response.data.pages;
            this.statData = [
              {
                title: "Total",
                value: response.data.total.toString(),
              },
            ];
          }
        })
        .catch((error) => {
          this.table.errored = error;
        })
        .finally(() => {
          this.table.loading = false;
          if (this.table.body == "" || this.table.body == null) {
            this.table.empty = true;
          }
        });
    },
  },
  mounted() {
    this.getTeam();
  },
  watch: {
    page: function () {
      window.scrollTo(0,0);
      this.getTeam();
    },
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t("Team") }}</h4>
        </div>
      </div>
    </div>

    <div class="row">
      <div v-for="stat of statData" :key="stat.icon" class="col-md-4">
        <Stat :icon="stat.icon" :title="stat.title" :value="stat.value" />
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div v-if="table.errored" class="text-center">
              {{
                t(
                  "We apologize, we are unable to retrieve information at this time. Please try again later."
                )
              }}
            </div>
            <div v-else-if="table.empty" class="text-center">{{ t("No records found.") }}</div>
            <div v-else class="table-responsive">
              <vcl-list v-if="table.loading" class="col-lg-6"></vcl-list>
              <table v-else class="table table-centered table-nowrap table-hover">
                <thead class="thead-light">
                  <tr>
                    <th>Usuário</th>
                    <th>Nível</th>
                    <th>Indicados</th>
                    <th>Tabuleiros</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(td, index) in table.body" :key="index">
                    <td class="notranslate">
                      {{ td.username }}
                    </td>
                    <th>{{ td.level }}</th>
                    <td>{{ td.sponsoreds }}</td>
                    <td>
                      <div class="d-flex">
                        <div
                          v-for="(boa, i) in td.boards"
                          :key="i"
                          :class="[boa.positions == 0 ? 'disabled' : '']"
                          class="board-position"
                        >
                          <img
                            v-b-tooltip.hover
                            style="width: 45px"
                            :class="[boa.positions == 0 ? 'disabled' : '']"
                            :src="
                              require('@/assets/images/boards/active.svg')
                            "
                          />
                          <sup class="board-total badge badge-light">{{
                            boa.positions
                          }}</sup>
                          <span class="board-name">{{ boa.name }}</span>
                        </div>
                      </div>
                    </td>
                    <td class="text-right">
                      <img class="img-responsive" :src="'https://flagcdn.com/24x18/' + td.country.toLowerCase() + '.png'">
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <paginate
              v-if="!table.errored && !table.empty"
              v-model="page"
              :page-count="pages"
              :page-range="3"
              :margin-pages="2"
              prev-text="<i class='mdi mdi-chevron-left'></i>"
              next-text="<i class='mdi mdi-chevron-right'></i>"
              :container-class="'pagination pagination-rounded justify-content-center mt-4'"
              :page-class="'page-item'"
              :page-link-class="'page-link'"
              :prev-link-class="'page-link'"
              :next-link-class="'page-link'"
              :break-view-link-class="'page-link'"
            >
            </paginate>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.divisor {
  position: relative;
  text-align: center;
  text-transform: uppercase;
}
.divisor span {
  display: inline-block;
  padding: 0 5px;
  background: #fff;
  font-size: 10px;
  letter-spacing: 2px;
  color: #888;
}
.divisor:before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  top: 52%;
  height: 1px;
  background: #ccc;
}
.board-position {
  position: relative;
  margin: 0 5px;
  width: 55px;
  text-align: center;
}
.board-position img {
  width: 100%;
}
.board-total {
  position: absolute;
  top: 0px;
  right: 0px;
}
.board-name {
  position: absolute;
  top: 16px;
  left: 0;
  width: 55px;
  font-size: 8px;
  text-transform: uppercase;
  color: #fff;
  background: #000;
  border-radius: 10px;
  text-align: center;
}
.disabled {
  filter: grayscale(1);
  opacity: 0.5;
}
</style>
